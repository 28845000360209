:root {
    --color-primary: #072E38;
    --color-secondary: #35ffac;
    --color-light: #f0fff8;
}

.bg-primary {
    background-color: var(--color-primary);
}

.bg-secondary {
    background-color: var(--color-secondary);
}

.bg-light {
    background-color: var(--color-light);
}

.border-primary {
    border-color: var(--color-primary);
}

.border-secondary {
    border-color: var(--color-secondary);
}

.border-light {
    border-color: var(--color-light);
}

.text-primary {
    color: var(--color-primary);
}

.text-secondary {
    color: var(--color-secondary);
}

.text-light {
    color: var(--color-light);
}

.fill-primary {
    fill: var(--color-primary);
}

.fill-secondary {
    fill: var(--color-secondary);
}

.stroke-primary {
    stroke: var(--color-primary);
}

.stroke-secondary {
    stroke: var(--color-secondary);
}

::-webkit-scrollbar-track {
    background-color: var(--color-light);
}

::-webkit-scrollbar {
    width: 6px;
    background: var(--color-light);
}

::-webkit-scrollbar-thumb {
    background: var(--color-primary);
}

.bg-opaque {
    background-color: rgba(7, 46, 56, 0.13);
}

.brightness-1000 {
    filter: brightness(1000);
}

.bg-opaque:hover {
    background-color: black;
}

.bg-primary-gradient {
    background: linear-gradient(to bottom, var(--color-primary) 45%, rgb(249, 250, 251) 99%);
}

.bg-button-gradient {
    background: linear-gradient(to right, #FFCF00 32.08%, #FFCF00 85.67%);
}

.text-gradient {
    background: linear-gradient(to right, #FFCF00 32.08%, #FFCF00 85.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&family=IBM+Plex+Sans&display=swap');

body {
    background-color: var(--color-light);
    margin: 0;
    padding: 0;
}

.container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

h2 {
    text-align: center;
    color: var(--color-primary);
    margin-bottom: 20px;
}

.login {
    margin-top: 10%;
}

.register {
    margin-top: 10%;
}

.campos {
    width: 95%;
    flex-direction: column;
}

.dashboard {
    max-width: 800px;
    margin: 50px auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
}

.quadro_extrato {
    max-width: 800px;
    margin: 50px auto;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.dashboard h3 {
    color: var(--color-primary);
}

.dashboard .section {
    margin: 20px 0;
}

.dashboard .section label {
    display: block;
    margin-bottom: 5px;
    color: var(--color-primary);
}

.dashboard .section input[type="text"],
.dashboard .section input[type="number"] {
    width: 97.5%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

th, td {
    border-bottom: 1px solid #e0e0e0;
    height: 70px;
}

.campos_extrato {
    background-color: var(--color-primary);
    color: white;
}

@font-face {
    font-family: "Phudu";
    src: url("../assets/MavenPro-Regular.ttf") format("truetype");
}

body {
    font-family: "Phudu", sans-serif;
}

@font-face {
    font-family: "Phudu";
    src: url("../assets/MavenPro-VariableFont_wght.ttf") format("truetype");
}

.font-patter-bold {
    font-family: "Phudu", sans-serif;
}
